/// <reference types="bootstrap"/>
/// <reference types="jquery"/>
import 'bootstrap';
class BootstrapTabComponent {
    constructor(config) {
        this.element = config.element;
    }
    run() {
        this.element.addEventListener('click', event => {
            event.preventDefault();
            jQuery(this.element).tab('show');
        });
        return this;
    }
}
export { BootstrapTabComponent };
