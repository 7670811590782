/// <reference types="swiper"/>
import 'core-js/stable/object/assign';
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, Thumbs } from 'swiper/modules';
import { Data } from "../utils/data";
class SwiperComponent {
    constructor(config) {
        this.options = {};
        this.element = config.element;
        Object.assign(this.options, this.optionsFromDataAttributes());
        if (config.options) {
            Object.assign(this.options, config.options);
        }
        this.loadModules();
    }
    optionsFromDataAttributes() {
        const options = {};
        Object.assign(options, this.element.dataset);
        for (const optionsKey in options) {
            options[optionsKey] = Data.getData(options[optionsKey]);
        }
        return options;
    }
    loadModules() {
        if (!this.options.hasOwnProperty('modules')) {
            this.options.modules = [];
        }
        for (const optionsKey in this.options) {
            if (optionsKey === 'autoplay') {
                this.options.modules.push(Autoplay);
            }
            if (optionsKey === 'navigation') {
                this.options.modules.push(Navigation);
            }
            if (optionsKey === 'pagination') {
                this.options.modules.push(Pagination);
            }
            if (optionsKey === 'thumbs') {
                this.options.modules.push(Thumbs);
            }
        }
        return;
    }
    getInstance() {
        return this.instance;
    }
    run() {
        this.instance = new Swiper(this.element, this.options);
        return this;
    }
}
export { SwiperComponent };
