class ScrollToComponent {
    constructor(config) {
        this.step = (timestamp) => {
            const progress = Math.min((timestamp - this.start) / this.scrollDuration, 1);
            this.setScrollTop(this.initScrollTop - Math.round(this.ease(progress) * this.pxsToScrollBy));
            if (progress < 1) {
                requestAnimationFrame(this.step);
            }
        };
        this.triggerElement = config.triggerElement;
        this.destination = config.destination;
        this.ease = config.ease;
        if (config.scrollContainer) {
            this.scrollContainer = config.scrollContainer;
        }
        else {
            this.scrollContainer = document.body;
        }
        if (config.scrollDuration) {
            this.scrollDuration = config.scrollDuration;
        }
        else {
            this.scrollDuration = 100;
        }
        if (config.ease) {
            this.ease = config.ease;
        }
        this.scrollDocumentElement = (this.scrollContainer === document.body) && document.documentElement;
        this.start = 0;
        this.initScrollTop = 0;
        this.pxsToScrollBy = 0;
    }
    run() {
        this.initEventListeners();
    }
    initEventListeners() {
        if (this.triggerElement) {
            this.triggerElement.addEventListener('click', (event) => this.clickHandler(event));
        }
    }
    clickHandler(event) {
        this.scroll();
    }
    scroll() {
        const scrollTo = typeof this.destination === 'function' ? this.destination() : this.destination.offsetTop;
        const { performance, requestAnimationFrame } = window;
        if (this.scrollDuration <= 0 || typeof performance === 'undefined' || typeof requestAnimationFrame === 'undefined' || !this.ease) {
            return this.setScrollTop(scrollTo);
        }
        this.start = performance.now();
        this.initScrollTop = this.getScrollTop();
        this.pxsToScrollBy = this.initScrollTop - scrollTo;
        requestAnimationFrame(this.step);
        return;
    }
    getScrollTop() {
        return this.scrollContainer.scrollTop || (this.scrollDocumentElement && document.documentElement.scrollTop || 0);
    }
    setScrollTop(value) {
        this.scrollContainer.scrollTop = value;
        if (this.scrollDocumentElement) {
            document.documentElement.scrollTop = value;
        }
    }
}
export { ScrollToComponent };
