class Data {
    static getData(data) {
        if (data === "true") {
            return true;
        }
        if (data === "false") {
            return false;
        }
        if (data === "null") {
            return null;
        }
        // Only convert to a number if it doesn't change the string
        if (data === +data + "") {
            return +data;
        }
        if (this.rbtrace.test(data)) {
            return JSON.parse(data);
        }
        return data;
    }
}
Data.rbtrace = /^(?:\{[\w\W]*\}|\[[\w\W]*\])$/;
export { Data };
